import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ScrollToTopRoute from "./components/scrolltotop/ScrollToTopRoute";
import {
  AboutUs,
  NotFound,
  GameSingle,
  Upcoming,
  BlogDetails,
  Blogs,
  Community,
  Contact,
  HomeTwo,
  Services,
} from "./constants/exports";
import SubcategoryPage from "./pages/SubcategoryPage/SubcategoryPage";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
            background: "transparent",
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)",
          }}
        >
          <img src="/loader.gif" alt="loader" />
        </div>
      ) : (
        <Router>
          <Switch>
            <ScrollToTopRoute exact={true} path="/">
              <HomeTwo />
            </ScrollToTopRoute>

            <ScrollToTopRoute exact={true} path="/contact">
              <Contact />
            </ScrollToTopRoute>
            <ScrollToTopRoute exact={true} path="/community">
              <Community />
            </ScrollToTopRoute>
            <ScrollToTopRoute exact={true} path="/services">
              <Services />
            </ScrollToTopRoute>
            <ScrollToTopRoute exact={true} path="/about">
              <AboutUs />
            </ScrollToTopRoute>
            <ScrollToTopRoute exact={true} path="/upcoming-games">
              <Upcoming />
            </ScrollToTopRoute>
            <ScrollToTopRoute exact={true} path="/game-single">
              <GameSingle />
            </ScrollToTopRoute>
            <ScrollToTopRoute exact={true} path="/blogs">
              <Blogs />
            </ScrollToTopRoute>
            <ScrollToTopRoute exact={true} path="/blog-details">
              <BlogDetails />
            </ScrollToTopRoute>
            <ScrollToTopRoute exact={true} path="/services/:subcategory">
              <SubcategoryPage />
            </ScrollToTopRoute>

            <ScrollToTopRoute>
              <NotFound />
            </ScrollToTopRoute>
          </Switch>
        </Router>
      )}
    </div>
  );
}

export default App;
