import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { data } from "../../constants/pagesData";
import NotFound from "../notFound/NotFound";
import TechUsing from "./TechUsing";

const Card = ({ cardTitle, cardContent, cardImage }) => {
  return (
    <div className="custom-card">
      {/* <img src={cardImage} alt={cardTitle} className="card-cardImage" /> */}
      <div className="card-content">
        <h4 className="service-title text-dark">{cardTitle}</h4>
        <p className="service-description">{cardContent}</p>
      </div>
    </div>
  );
};

const Banner = ({ title, content, models, image }) => {
  const formatTitle = (title) => {
    const words = title.split(" ");
    const lastWord = words.pop();
    return (
      <>
        {words.join(" ")} <span>{lastWord}</span>
      </>
    );
  };
  return (
    <section className="banner-section latest-match-bg">
      <div className="container">
        <div className="section-title title-style-three white-title text-center mb-70">
          <h2 className="banner-title">{formatTitle(title)}</h2>
          <p className="banner-content">{content}</p>
        </div>
        <div className="models-list">
          {models.map((model, index) => (
            <div key={index} className="model-item tournament-schedule-content">
              <h3 className="model-title">{formatTitle(model.modelTitle)}</h3>
              <p className="model-content">{model.modelContent}</p>
            </div>
          ))}
        </div>
        <div
          className="banner-image"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      </div>
    </section>
  );
};

const SubcategoryPage = () => {
  const { subcategory } = useParams();
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (data[subcategory]) {
      setContent(data[subcategory]);
    } else {
      setContent(null);
    }
  }, [subcategory]);

  if (content === null) {
    return <NotFound />;
  }

  if (!content) return <div>Loading...</div>;

  const formatTitle = (title) => {
    const words = title.split(" ");
    const lastWord = words.pop();
    return (
      <>
        {words.join(" ")} <span>{lastWord}</span>
      </>
    );
  };

  return (
    <main className="subcategory-page">
      <Header />
      <section className="breadcrumb-area breadcrumb-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-content text-center">
                <h2>{formatTitle(content.title)}</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>

                    <li className="breadcrumb-item active" aria-current="page">
                      {content.title}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "start",
          height: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "70px",
          }}
        >
          <div className="section-title title-style-three white-title text-center mb-20 services_upper ">
            <h2 className="text-dark">{content.title}</h2>
          </div>
          <p className="services_content">{content.content}</p>
        </div>
        <div
          className="cards-container row justify-content-start"
          style={{
            marginTop: "24px",
            gap: "24px",
            flexWrap: "wrap",
            maxWidth: "50%",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start !important",
          }}
        >
          {content.cards.map((card, index) => (
            <Card
              key={index}
              cardTitle={card.cardTitle}
              cardContent={card.cardContent}
              cardImage={card.cardImage}
            />
          ))}
        </div>
        {content.banner && (
          <Banner
            title={content.banner.title}
            content={content.banner.content}
            models={content.banner.models}
            image={content.banner.image}
          />
        )}
        <TechUsing />
      </div>

      <Footer />
    </main>
  );
};

export default SubcategoryPage;
