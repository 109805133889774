import React from "react";
import Breadcrumb from "./Breadcrumb";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Blogarea from "./Blogarea";

function Blogs() {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb />
        <Blogarea />
      </main>
      <Footer />
    </>
  );
}

export default Blogs;
