import React, { useEffect } from "react";
import WOW from "wowjs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function AboutInner() {
  useEffect(() => {
    new WOW.WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
      loop: Infinity,
    }).init();
  }, []);
  return (
    <section className="inner-about-area fix">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6 order-0 order-lg-2">
            <div className="inner-about-img">
              <img
                src="/assets/img/images/inner_about_img01.png"
                className="wow fadeInRight"
                data-wow-delay=".3s"
                alt=""
              />
              <img
                src="/assets/img/images/inner_about_img02.png"
                className="wow fadeInLeft"
                data-wow-delay=".6s"
                alt=""
              />
              <img
                src="/assets/img/images/inner_about_img03.png"
                className="wow fadeInUp"
                data-wow-delay=".6s"
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="section-title title-style-three mb-25">
              <h2>
                About <span>GAMICAN</span>
              </h2>
            </div>
            <div className="inner-about-content">
              {/* <h5>Monica Global Publishing for Marketing</h5> */}
              <p>
                At Gamican Pvt, we are committed to delivering exceptional
                products that resonate with users. Our approach involves
                rigorous research and development to ensure we remain at the
                forefront of technological advancements. Our comprehensive
                Software Development Life Cycle (SDLC) plans serve as blueprints
                for crafting robust and reliable solutions.
              </p>
              <p>
                Gamican Pvt is currently focused on expanding its reach. We are
                dedicated to developing high-ranking Android games with an
                emphasis on creating intuitive and user-friendly experiences.
                Our innovative Android games offer immersive gameplay and
                engaging storytelling.
              </p>
              {/* <Link to="/#" className="btn btn-style-two">
                Learn More
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="inner-about-shape">
        <img src="/assets/img/images/medale_shape.png" alt="" />
      </div>
    </section>
  );
}

export default AboutInner;
