import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function CommunityArea() {
  return (
    <div className="community-area primary-bg pt-120 pb-175">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="community-wrap">
              <div className="table-responsive-xl">
                <table className="table mt-0">
                  <thead>
                    <tr>
                      <th scope="col">mark all as soon</th>
                      <th scope="col">VOICES</th>
                      <th scope="col">POSTS</th>
                      <th scope="col">See</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="community-post-type">
                      <th>
                        <span>Game Concept</span>
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div className="community-post-wrap">
                          <div className="community-post-content">
                            <Link to="/#">Electronic game that involves</Link>
                            <span>
                              by <Link to="/#">Tanvir snap</Link> Yesterday 12 :
                              19
                            </span>
                          </div>
                          <div className="community-post-tag">
                            <Link to="/#">@All</Link>
                          </div>
                        </div>
                      </th>
                      <td>3</td>
                      <td>4</td>
                      <td className="community-bell">
                        <Link to="/#">
                          <i className="far fa-bell" />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div className="community-post-wrap">
                          <div className="community-post-content">
                            <Link to="/#">A vertically scrolling video</Link>
                            <span>
                              by <Link to="/#">Killer12</Link> Yesterday 12 : 19
                            </span>
                          </div>
                          <div className="community-post-tag">
                            <Link to="/#">@All</Link>
                          </div>
                        </div>
                      </th>
                      <td>5</td>
                      <td>9</td>
                      <td className="community-bell">
                        <Link to="/#">
                          <i className="far fa-bell" />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div className="community-post-wrap">
                          <div className="community-post-content">
                            <Link to="/#">Electronic game that involves</Link>
                            <span>
                              by <Link to="/#">Tanvir snap</Link> Yesterday 12 :
                              19
                            </span>
                          </div>
                          <div className="community-post-tag">
                            <Link to="/#">@All</Link>
                            <Link to="/#">@Design</Link>
                          </div>
                        </div>
                      </th>
                      <td>7</td>
                      <td>12</td>
                      <td className="community-bell">
                        <Link to="/#">
                          <i className="far fa-bell" />
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive-xl">
                <table className="table">
                  <tbody>
                    <tr className="community-post-type">
                      <th>
                        <span>Zombie Island</span>
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div className="community-post-wrap">
                          <div className="community-post-content">
                            <Link to="/#">Electronic game that involves</Link>
                            <span>
                              by <Link to="/#">Tanvir snap</Link> Yesterday 12 :
                              19
                            </span>
                          </div>
                          <div className="community-post-tag">
                            <Link to="/#">@All</Link>
                          </div>
                        </div>
                      </th>
                      <td>3</td>
                      <td>4</td>
                      <td className="community-bell">
                        <Link to="/#">
                          <i className="far fa-bell" />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div className="community-post-wrap">
                          <div className="community-post-content">
                            <Link to="/#">A vertically scrolling video</Link>
                            <span>
                              by <Link to="/#">Killer12</Link> Yesterday 12 : 19
                            </span>
                          </div>
                          <div className="community-post-tag">
                            <Link to="/#">@Design</Link>
                          </div>
                        </div>
                      </th>
                      <td>5</td>
                      <td>9</td>
                      <td className="community-bell">
                        <Link to="/#">
                          <i className="far fa-bell" />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div className="community-post-wrap">
                          <div className="community-post-content">
                            <Link to="/#">Electronic game that involves</Link>
                            <span>
                              by <Link to="/#">Tanvir snap</Link> Yesterday 12 :
                              19
                            </span>
                          </div>
                          <div className="community-post-tag">
                            <Link to="/#">@All</Link>
                          </div>
                        </div>
                      </th>
                      <td>7</td>
                      <td>12</td>
                      <td className="community-bell">
                        <Link to="/#">
                          <i className="far fa-bell" />
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="create-topic">
                <span>Viewing 6 topics - (of 7 Total)</span>
                <form>
                  <input
                    type="text"
                    placeholder="Must be logged in to create new topics."
                  />
                  <button className="btn">submit</button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="community-sidebar">
              <div className="widget community-widget mb-30">
                <div className="community-widget-title mb-25">
                  <h5>share public</h5>
                </div>
                <div className="community-sidebar-social">
                  <ul>
                    <li>
                      <Link to="/#">
                        <i className="fab fa-facebook-f" />
                        Share With Facebook
                      </Link>
                    </li>
                    <li>
                      <Link to="/#">
                        <i className="fab fa-twitter" />
                        Share With Twitter
                      </Link>
                    </li>
                    <li>
                      <Link to="/#">
                        <i className="fab fa-pinterest-p" />
                        Share With Pinterest
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="widget community-widget">
                <div className="community-widget-title mb-25">
                  <h5>new released game</h5>
                </div>
                <div className="community-sidebar-game">
                  <ul>
                    <li>
                      <div className="sidebar-new-game-thumb">
                        <Link to="/#">
                          <img
                            src="/assets/img/images/sidebar_new_game01.jpg"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="sidebar-new-game-content">
                        <span>Yesterday 12 : 19</span>
                        <h5>
                          <Link to="/#">Sky Warrior Game involves</Link>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <div className="sidebar-new-game-thumb">
                        <Link to="/#">
                          <img
                            src="/assets/img/images/sidebar_new_game02.jpg"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="sidebar-new-game-content">
                        <span>Yesterday 12 : 19</span>
                        <h5>
                          <Link to="/#">Sky Warrior Game involves</Link>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <div className="sidebar-new-game-thumb">
                        <Link to="/#">
                          <img
                            src="/assets/img/images/sidebar_new_game03.jpg"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="sidebar-new-game-content">
                        <span>Yesterday 12 : 19</span>
                        <h5>
                          <Link to="/#">Sky Warrior Game involves</Link>
                        </h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
      <div className="community-bg-shape">
        <img src="/assets/img/images/community_bg_shape.png" alt="" />
      </div>
    </div>
  );
}

export default CommunityArea;
