import React from "react";
import Breadcrumb from "./Breadcrumb";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ContactArea from "./ContactArea";
import Brand from "../../components/index/Brand";

function Contact() {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb />
        <ContactArea />
        <Brand />
      </main>
      <Footer />
    </>
  );
}

export default Contact;
