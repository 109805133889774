import React from "react";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import Breadcrumb from "./Breadcrumb";
import UpcomingGames from "./UpcomingGames";
import LatestMatch from "../LatestMatch";
import AboutInner from "../AboutInner";

function Upcoming() {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb />
        <UpcomingGames />
        <LatestMatch />
        <AboutInner />
      </main>
      <Footer />
    </>
  );
}

export default Upcoming;
