import $ from "jquery";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import "../../assets/css/customStyles.css";

export default function Header() {
  const location = useLocation();

  const menuData = [
    {
      category: "Game Development",
      image:
        "https://kevurugames.com/wp-content/themes/classy/assets/refactored/pages/img/icons/svg/list.svg",
      subcategories: [
        "Android Game Development",
        "iOS Game Development",
        "Subcategory Three",
        "MMORPG Game Development",
        "Game Testing",
        "Game Porting",
        "Unity Development",
        "Unreal Development",
        "PC Game Development",
        "Mobile Game Development",
        "Hyper Casual Game Development",
        "Gamification Services",
      ],
    },
    {
      category: "Game Art",
      image:
        "https://kevurugames.com/wp-content/themes/classy/assets/refactored/pages/img/icons/svg/list.svg",
      subcategories: [
        "Concept Art",
        "Casual Art",
        "Environment Design",
        "Character Design",
        "Hidden Objects",
        "Slots Games Art",
        "AAA",
        "2D Art",
        "2D Characters Design",
        "2D Environment Design",
        "3D Art",
        "3D Characters Design",
        "3D Environment Design",
        "3D Game Modeling",
        "3D Sculpting",
        "UI/UX Design",
        "Illustration Services",
        "Game Economy Design",
        "Game Level Design",
      ],
    },
    {
      category: "Game Animation",
      image:
        "https://kevurugames.com/wp-content/themes/classy/assets/refactored/pages/img/icons/svg/list.svg",
      subcategories: [
        "2D Animation",
        "3D Animation",
        "Motion Graphic Animation",
      ],
    },
  ];

  useEffect(() => {
    // SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    // Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".menu-area .push-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      // Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );

      $(".menu-backdrop, .mobile-menu .close-btn").click(() => {
        $("body").removeClass("mobile-menu-visible");
      });

      // Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });
    }
  }, []);

  useEffect(() => {
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $(".scroll-to-target").removeClass("open");
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $(".scroll-to-target").addClass("open");
      }
    });
  }, []);

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <header>
      <ScrollToTop smooth top="500" color="#000" />
      <div className="header-top-area s-header-top-area d-none d-lg-block">
        <div className="container-fluid s-container-full-padding">
          <div className="row align-items-center">
            <div className="col-lg-6 d-none d-lg-block">
              {/* <div className="header-top-offer">
                <p style={{ color: "#ff7f38" }}>
                  Exclusive Black Friday ! Offer
                </p>
                <span className="coming-time" data-countdown="2022/3/15" />
              </div> */}
            </div>
            <div className="col-lg-6">
              <div className="header-top-right">
                <div className="header-social">
                  <ul>
                    <li>
                      <Link to="/#">
                        <i className="fab fa-facebook-f" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/#">
                        <i className="fab fa-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/#">
                        <i className="fab fa-pinterest-p" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/#">
                        <i className="fab fa-linkedin-in" />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="header-top-action">
                  <ul>
                    <li>
                      <div className="header-top-mail">
                        <p>
                          <span>|</span>
                          <i className="far fa-envelope" />
                          <Link to="/mailto:info@gamican.com">
                            info@gamican.com
                          </Link>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="sticky-header" className="transparent-header menu-area">
        <div className="container-fluid s-container-full-padding">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler two">
                <i className="fas fa-bars" />
              </div>
              <div className="main-menu menu-style-two">
                <nav>
                  <div className="logo">
                    <Link to="/">
                      <img
                        src="/logo.png"
                        alt="Logo"
                        style={{
                          width: "150px",
                        }}
                      />
                    </Link>
                  </div>
                  <div className="navbar-wrap push-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li
                        className={`menu-item-has-children ${
                          isActive("/services")
                            ? "active-li header-li"
                            : "header-li"
                        }`}
                      >
                        <Link to="/services">
                          Services <i className="fa fa-chevron-down"></i>
                        </Link>
                        <ul className="dropdown-menu mega-menu">
                          {menuData.map((item, index) => (
                            <li key={index}>
                              <div className="row">
                                <ul className="media-list ">
                                  <li className="media">
                                    <div className="media-body">
                                      <h5 className="media-heading">
                                        <Link
                                          to="#"
                                          style={{
                                            color: "#ff7f38",
                                            padding: "0",
                                            marginBottom: "16px",
                                            fontSize: "18px",
                                          }}
                                        >
                                          <img
                                            src={item.image}
                                            alt=""
                                            className="media-object"
                                          />
                                          {item.category}
                                        </Link>
                                      </h5>
                                      <ul className="unstyled">
                                        {item.subcategories
                                          .slice(0, 6)
                                          .map((subcategory, subIndex) => (
                                            <li key={subIndex}>
                                              <Link
                                                to={`/services/${encodeURIComponent(
                                                  subcategory
                                                )}`}
                                                style={{
                                                  marginBottom: "18px",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {subcategory}
                                              </Link>
                                            </li>
                                          ))}
                                        {item.subcategories.length > 6 && (
                                          <li>
                                            <Link
                                              to="/services"
                                              style={{
                                                fontSize: "13px",
                                                color: "#ff7f38",
                                              }}
                                            >
                                              Show All
                                            </Link>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </li>

                      {/* <li
                        className={
                          isActive("/solutions")
                            ? "active-li header-li"
                            : "header-li"
                        }
                      >
                        <Link to="/solutions">Solutions</Link>
                      </li> */}
                      <li
                        className={
                          isActive("/about")
                            ? "active-li header-li"
                            : "header-li"
                        }
                      >
                        <Link to="/about">About</Link>
                      </li>
                      <li
                        className={
                          isActive("/careers")
                            ? "active-li header-li"
                            : "header-li"
                        }
                      >
                        <Link to="/career">Careers</Link>
                      </li>
                      <li
                        className={
                          isActive("/contact")
                            ? "active-li header-li"
                            : "header-li"
                        }
                      >
                        <Link to="/blogs">Blog</Link>
                      </li>

                      <li>
                        <div className="header-top-login">
                          <li className="">
                            <Link to="/contact" className="btn btn-style-two">
                              <i class="fa fa-envelope" aria-hidden="true"></i>
                              Contact
                            </Link>
                          </li>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo">
                    <Link to="/">
                      <img src="/logo.png" alt="" title="" />
                    </Link>
                  </div>
                  <div className="menu-outer"></div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li>
                        <Link to="#">
                          <span className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span className="fab fa-facebook-square" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span className="fab fa-pinterest-p" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span className="fab fa-youtube" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
