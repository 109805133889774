import React from "react";
import { Route, withRouter } from "react-router-dom";

function ScrollToTopRoute(props) {
  window.scrollTo(0, 0);
  const { ...rest } = props;
  return <Route {...rest} />;
}

export default withRouter(ScrollToTopRoute);
