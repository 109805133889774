import React from "react";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import Breadcrumb from "../upcoming/Breadcrumb";
import SingleArea from "./SingleArea";
import ReleaseGame from "./ReleaseGame";

function GameSingle() {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb />
        <SingleArea />
        <ReleaseGame />
      </main>
      <Footer />
    </>
  );
}

export default GameSingle;
