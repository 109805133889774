import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const NotFound = () => {
  return (
    <main>
      <Header />
      <div
        className="flex"
        style={{
          background: "black",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <h1
          style={{
            color: "white",
          }}
        >
          404 - Page Not Found
        </h1>
        <p>The page you are looking for does not exist.</p>
      </div>
      <Footer/>
    </main>
  );
};

export default NotFound;
