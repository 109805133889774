import React from "react";
import { Link } from "react-router-dom";

const BlogArea = () => {
  return (
    <section className="blog-area pt-115 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title home-four-title black-title text-center mb-65">
              <h2>
                Latest News &amp; <span>Articles</span>
              </h2>
              <p>
                Compete with 100 players on a remote island for winner takes
                showdown known issue where certain skin strategic
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="blog-post home-four-blog-post mb-50">
              <div className="blog-thumb mb-30">
                <Link to="/#">
                  <img
                    src="/assets/img/blog/home_four_blog_thumb01.jpg"
                    alt=""
                  />
                </Link>
              </div>
              <div className="blog-post-content">
                <div className="blog-meta">
                  <ul>
                    <li>
                      <i className="far fa-user" />
                      <Link to="/#">Admin</Link>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" />
                      september 19, 2020
                    </li>
                  </ul>
                </div>
                <h4>
                  <Link to="/#">Shooter action video</Link>
                </h4>
                <p>
                  Compete with 100 players on a remote island thats winner takes
                  showdown known issue.
                </p>
                <Link to="/blog-details" className="read-more">
                  Read More <i className="fas fa-caret-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="blog-post home-four-blog-post mb-50">
              <div className="blog-thumb mb-30">
                <Link to="/#">
                  <img
                    src="/assets/img/blog/home_four_blog_thumb02.jpg"
                    alt=""
                  />
                </Link>
              </div>
              <div className="blog-post-content">
                <div className="blog-meta">
                  <ul>
                    <li>
                      <i className="far fa-user" />
                      <Link to="/#">Admin</Link>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" />
                      september 19, 2020
                    </li>
                  </ul>
                </div>
                <h4>
                  <Link to="/#">THE WALKING DEAD</Link>
                </h4>
                <p>
                  Compete with 100 players on a remote island thats winner takes
                  showdown known issue.
                </p>
                <Link to="/blog-details" className="read-more">
                  Read More <i className="fas fa-caret-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="blog-post home-four-blog-post mb-50">
              <div className="blog-thumb mb-30">
                <Link to="/#">
                  <img
                    src="/assets/img/blog/home_four_blog_thumb03.jpg"
                    alt=""
                  />
                </Link>
              </div>
              <div className="blog-post-content">
                <div className="blog-meta">
                  <ul>
                    <li>
                      <i className="far fa-user" />
                      <Link to="/#">Admin</Link>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" />
                      september 19, 2020
                    </li>
                  </ul>
                </div>
                <h4>
                  <Link to="/#">DEFENSE OF THE ANCIENTS</Link>
                </h4>
                <p>
                  Compete with 100 players on a remote island thats winner takes
                  showdown known issue.
                </p>
                <Link to="/blog-details" className="read-more">
                  Read More <i className="fas fa-caret-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogArea;
