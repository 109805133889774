import React from "react";
import BreadCrumb from "./BreadCrumb";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ShopArea from "./ShopArea";

function Shop() {
  return (
    <>
      <Header />
      <main>
        <BreadCrumb />
        <ShopArea />
      </main>
      <Footer />
    </>
  );
}

export default Shop;
