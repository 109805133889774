import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import FeaturesArea from "../../components/index/FeaturesArea";
import AboutArea from "../../components/index/AboutArea";
import BreadCrumb from "./Breadcrumb";
import AboutInner from "../aboutpage/AboutInner";
import ExpertiseTable from "./ExpertiseTable";

function Services() {
  return (
    <>
      <Header />
      <main>
        <BreadCrumb />
        <AboutInner />
        <AboutArea />
        <ExpertiseTable />
        <FeaturesArea />
      </main>
      <Footer />
    </>
  );
}

export default Services;
