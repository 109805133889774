import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Breadcrumb from "./Breadcrumb";
import AboutInner from "./AboutInner";
import LatestMatch from "./LatestMatch";
import TeamMem from "./TeamMem";
import MissionVision from "./Mission";
import WhyGamican from "../../components/index/WhyGamican";

function AboutUs() {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb />
        <AboutInner />
        <MissionVision />
        <WhyGamican />
        {/* <TeamMem /> */}
      </main>
      <Footer />
    </>
  );
}

export default AboutUs;
