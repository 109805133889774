import React, { useEffect } from "react";
import WOW from "wowjs";

function MissionVision() {
  useEffect(() => {
    new WOW.WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    }).init();
  }, []);

  return (
    <section className="mission-vision-area fix">
      <div className="container">
        {/* Our Mission Section */}
        <div className="row align-items-center mb-60">
          <div className="col-xl-5 col-lg-6 order-2 order-lg-1">
            <div className="mission-img">
              <img
                src="/assets/img/blog/blog_details_img01.jpg"
                className="wow fadeInLeft"
                data-wow-delay=".3s"
                alt="Mission"
              />
            </div>
          </div>
          <div className="col-xl-7 col-lg-6 order-1 order-lg-2">
            <div className="section-title title-style-three mb-25">
              <h2>
                Our <span>Mission</span>
              </h2>
            </div>
            <div className="mission-content">
              <p style={{ color: "#1C1121" }}>
                As we move forward, we uphold our core values of quality,
                creativity, and innovation.Our mission to diversify our
                offerings while maintaining the highest standards reflects our
                unwavering commitment to the technology and entertainment
                sectors.
              </p>
            </div>
          </div>
        </div>

        {/* Our Vision Section */}
        <div className="row align-items-center mb-160">
          <div className="col-xl-7 col-lg-6">
            <div className="section-title title-style-three mb-25">
              <h2>
                Our <span>Vision</span>
              </h2>
            </div>
            <div className="vision-content">
              <p style={{ color: "#1C1121" }}>
                Our expertise extends to iOS development, where we leverage
                Apple’s ecosystem to produce seamless and polished games.
                Additionally, we are making strides in virtual reality, aiming
                to deliver unparalleled immersive gaming experiences.
              </p>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="vision-img">
              <img
                src="/assets/img/blog/blog_details_img01.jpg"
                className="wow fadeInRight"
                data-wow-delay=".3s"
                alt="Vision"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MissionVision;
