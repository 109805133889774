import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Expertises = [
  {
    icon: "https://kevurugames.com/wp-content/uploads/2022/06/icon-1.svg",
    title: "Game Development",
    link: "#",
    description:
      "We offer a full range of game development services for the most popular platforms...",
    subitems: [
      {
        name: "Android Game Development",
        link: "",
      },
      {
        name: "iOS Game Development",
        link: "",
      },
      {
        name: "Subcategory Three",
        link: "",
      },
      {
        name: "MMORPG Game Development",
        link: "",
      },
      {
        name: "Game Testing",
        link: "",
      },
      {
        name: "Game Porting",
        link: "",
      },
      {
        name: "Unity Development",
        link: "",
      },
      {
        name: "Unreal Development",
        link: "",
      },
      {
        name: "PC Game Development",
        link: "",
      },
      {
        name: "Mobile Game Development",
        link: "",
      },
      {
        name: "Hyper Casual Game Development",
        link: "",
      },
      {
        name: "Gamification Services",
        link: "",
      },
    ],
  },
  {
    icon: "https://kevurugames.com/wp-content/uploads/2022/06/icon-3.svg",
    title: "GAME ANIMATION",
    link: "",
    description:
      "The Kevuru Games animation team will help bring 2D and 3D characters and environments to life...",
    subitems: [
      {
        name: "2D Animation",
        link: "",
      },
      {
        name: "3D Animation",
        link: "",
      },
      {
        name: "Concept Art",
        link: "",
      },
      {
        name: "Casual Art",
        link: "",
      },
      {
        name: "Environment Design",
        link: "",
      },
      {
        name: "Character Design",
        link: "",
      },

      {
        name: "Hidden Objects",
        link: "",
      },
      {
        name: "Slots Games Art",
        link: "",
      },
      {
        name: "AAA",
        link: "",
      },
      {
        name: "2D Art",
        link: "",
      },
      {
        name: "2D Characters Design",
        link: "",
      },
      {
        name: "2D Environment Design",
        link: "",
      },
      {
        name: "3D Art",
        link: "",
      },
      {
        name: "3D Characters Design",
        link: "",
      },
      {
        name: "3D Environment Design",
        link: "",
      },
      {
        name: "3D Game Modeling",
        link: "",
      },
      {
        name: "3D Sculpting",
        link: "",
      },
      {
        name: "UI/UX Design",
        link: "",
      },
      {
        name: "Illustration Services",
        link: "",
      },
      {
        name: "Game Economy Design",
        link: "",
      },
      {
        name: "Game Level Design",
        link: "",
      },
    ],
  },
  {
    icon: "https://kevurugames.com/wp-content/uploads/2022/06/icon-2.svg",
    title: "GAME ART",
    link: "",
    description:
      "Our artists have experience in creating art for the world's leading gaming companies...",
    subitems: [
      {
        name: "Concept Art",
        link: "",
      },
      {
        name: "Casual Art",
        link: "",
      },
      {
        name: "Motion Graphic Animation",
        link: "",
      },
    ],
  },
];

const ExpertiseTable = () => {
  const formatTitle = (title) => {
    const words = title.split(" ");
    const lastWord = words.pop();
    return (
      <>
        {words.join(" ")}{" "}
        <span
          style={{
            color: "#ff7f38",
          }}
        >
          {lastWord}
        </span>
      </>
    );
  };
  return (
    <>
      <div
        className="mt-90"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="section-title title-style-three white-title text-center mb-20 services_upper ">
          <h2 className="text-dark">
            Learn more about our <span>EXPERTISE</span>
          </h2>
        </div>
        <p className="services_content">
          Check out the full list of our services – each department contains
          top-class specialists who can see the essence of the request and
          perform effective results that bring direct benefits to your business.
        </p>
      </div>
      <div className="expertise-container mb-90">
        {Expertises.map((expertise, index) => (
          <div className="expertise-item" key={index}>
            <div className="expertise-icon">
              {/* <img src={expertise.icon} alt={`${expertise.title} icon`} /> */}
            </div>
            <div className="expertise-inner">
              <h2
                className="text-dark text-start"
                style={{ borderBottom: "1px solid gray" }}
              >
                <Link to={expertise.link}>{formatTitle(expertise.title)}</Link>
              </h2>
              <div className="expertise-desc">{expertise.description}</div>
              <ul className="expertise-list">
                {expertise.subitems.map((subitem, subindex) => (
                  <li className="expertise-item expertise-li" key={subindex}>
                    <img
                      src="https://kevurugames.com/wp-content/themes/classy/assets/refactored/pages/img/icons/svg/list.svg"
                      alt=""
                      style={{ width: "24px", filter: "invert(61)" }}
                      className="media-object"
                    />
                    <Link to={`/services/${encodeURIComponent(subitem.name)}`}>
                      {subitem.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ExpertiseTable;
