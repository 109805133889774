export const data = {
  "Android Game Development": {
    title: "Android Game Development",
    content:
      "If you are looking for the best studio to hire Android game developers, then you can breathe easy: you've come to the right place. Our Android game development studio knows how to create games that will definitely resonate with your target audience. We can offer you the following services.",
    cards: [
      {
        cardTitle: "Full-Cycle Android Game Development",
        cardContent:
          "Our Android game developers have the expertise, technical capabilities, and artistic vision to create a game product of any complexity from scratch. We think over and implement the most relevant game mechanics in the game so that the players appreciate it.",
        cardImage: "path/to/card1-image.jpg",
      },
      {
        cardTitle: "Assets Creation",
        cardContent:
          "Each game in our Android studio is filled with juicy and eye-catching graphics, beautiful and smooth animation, and attractive assets with which the player wants to continuously interact. We create perfectly optimized game elements for the best performance.",
        cardImage: "path/to/card2-image.jpg",
      },
      {
        cardTitle: "UX/UI Design",
        cardContent:
          "The user interface plays an important role in the convenience of players interacting with the product and their desire to continue playing sessions. Therefore, we pay close attention to creating an intuitive interface that does not distract from the game and fits perfectly into its setting and style.",
        cardImage: "path/to/card2-image.jpg",
      },
      {
        cardTitle: "Testing",
        cardContent:
          "Our Android studio gaming services include comprehensive and effective game quality control based on development criteria and goals. We track bugs and errors, ensure high performance and compatibility, establish social integration, and so on so that there are no complaints about the game.",
        cardImage: "path/to/card2-image.jpg",
      },
      {
        cardTitle: "Support and Maintenance",
        cardContent:
          "After the end of development, we continue to provide support and maintenance for the game. This service includes the creation of additional game elements, new levels and mechanics, making changes to the functions and design of game elements based on player comments and more.",
        cardImage: "path/to/card2-image.jpg",
      },
    ],
    banner: {
      title: "Cooperation Models",
      content:
        "We offer services to our clients based on several interaction models that can be tailored to meet your specific needs. You can choose one of the available models or combine them. We are ready to help you by choosing the most suitable model for you.",
      models: [
        {
          modelTitle: "Fixed Price",
          modelContent:
            "We offer innovative Android studio game development solutions at a fixed price that is based on the detailed specifications of the project.",
        },
        {
          modelTitle: "Time and Material",
          modelContent:
            "T&M pricing includes flexible rates and agile project execution. This model is suitable for long term projects with changing requirements.",
        },
        {
          modelTitle: "Managed outsourcing",
          modelContent:
            "We offer not only an experienced team capable of providing Android game development services, but also managed delivery to",
        },
        {
          modelTitle: "Dedicated Team",
          modelContent:
            "A dedicated offshore team is offered as an extension of your own infrastructure with full support and access to all resources and facilities.",
        },
      ],
      image:
        "https://kevurugames.com/wp-content/uploads/2020/10/platforms-img.jpg",
    },
  },
};
