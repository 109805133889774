import React from "react";
import Slider from "react-slick";

function TechUsing() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    center: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div
      className="brand-area brand-bg"
      style={{ width: "100%", paddingBottom: "136px" }}
    >
      <div className="container">
        <div className="section-title title-style-three white-title text-center mb-70">
          <h2 className="banner-title">
            <span> Technologies </span> We are using
          </h2>
        </div>
        <Slider className="row brand-active" {...settings}>
          <div className="col-xl">
            <div className="brand-item">
              <img src="/tech-logos/tech_logo01.webp" alt="" />
            </div>
          </div>
          <div className="col-xl">
            <div className="brand-item">
              <img src="/tech-logos/tech_logo02.webp" alt="" />
            </div>
          </div>
          <div className="col-xl">
            <div className="brand-item">
              <img src="/tech-logos/tech_logo03.webp" alt="" />
            </div>
          </div>
          <div className="col-xl">
            <div className="brand-item">
              <img src="/tech-logos/tech_logo04.webp" alt="" />
            </div>
          </div>
          <div className="col-xl">
            <div className="brand-item">
              <img
                src="/tech-logos/tech_logo05.webp"
                alt=""
                style={{ filter: "brightness(13.5)" }}
              />
            </div>
          </div>
          <div className="col-xl">
            <div className="brand-item">
              <img
                src="/tech-logos/tech_logo06.webp"
                alt=""
                style={{ filter: "brightness(13.5)" }}
              />
            </div>
          </div>
          <div className="col-xl">
            <div className="brand-item">
              <img src="/tech-logos/tech_logo03.webp" alt="" />
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default TechUsing;
