import React from "react";

const WhyGamican = () => {
  const boxes = [
    {
      title: "Crafting Excellence in Game Development",
      description:
        "Our dedication to perfection ensures every game we develop stands out in quality, gameplay, and innovation, making your vision a reality.",
    },
    {
      title: "Long-Term Cooperation",
      description:
        "We build lasting partnerships, working closely with you throughout the development process and beyond, ensuring consistent support and collaboration.",
    },
    {
      title: "Trusted by Publisher",
      description:
        "Our reputation for reliability and excellence has earned the trust of publishers worldwide, making us a preferred partner in the gaming industry.",
    },
    {
      title: "Unreal Engine Service",
      description:
        "Harnessing the power of Unreal Engine, we create immersive, high-performance games that push the boundaries of visual fidelity and interactivity.",
    },
  ];

  return (
    <section style={{ padding: "60px 0", marginBottom: "60px" }}>
      <div
        style={{ maxWidth: "1200px", margin: "0 auto", textAlign: "center" }}
      >
        <h2 style={{ marginBottom: "40px", color: "#1C1121" }}>
          Why Choose <span style={{ color: "#FF7F38" }}>Gamican</span>
        </h2>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {boxes.map((box, index) => (
            <div
              key={index}
              style={{
                backgroundColor: "#f5f5f5",
                padding: "20px",
                textAlign: "center",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                width: "23%",
              }}
            >
              <h4
                style={{
                  color: "#1C1121",
                  marginBottom: "15px",
                  fontSize: "1.5rem",
                }}
              >
                {box.title}
              </h4>
              <p
                style={{
                  color: "#1C1121",
                  fontSize: "1rem",
                  lineHeight: "1.5",
                }}
              >
                {box.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyGamican;
