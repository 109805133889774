import React from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import SliderTwo from "./SliderTwo";
import GamesAreatwo from "./GamesAreatwo";
import GamesArea from "./GamesArea";
import CtaArea from "./CtaArea";
import BlogArea from "./BlogArea";
import FeatureArea from "./FeatureArea";
import AboutInner from "../../pages/aboutpage/AboutInner";
import WhyGamican from "./WhyGamican";

function IndexTwo() {
  return (
    <>
      <Header />

      <main>
        <SliderTwo />

        <AboutInner />
        {/* <FeatureArea /> */}
        <WhyGamican />
        <GamesAreatwo />
        <GamesArea />
        <CtaArea />
        <BlogArea />
      </main>
      <Footer />
    </>
  );
}

export default IndexTwo;
